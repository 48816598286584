import React, {  } from 'react'
import { signOut } from 'firebase/auth';
import { auth } from './firebase-config';
import Button from './components/button/Button';
import useNotificaiton from './components/notification/useNotification';
import NotificationBox from './components/notification/NotificationBox';

interface SignOutProps {
    authUser: any;
}

function SignOut({authUser}: SignOutProps) {
    const { notification, showTemporarily } = useNotificaiton()

    const userSignOut = () => {
        signOut(auth).then(() => {
            showTemporarily("Sign out succesful!", 'successful')
        }).catch((err) => {
            showTemporarily("Sign out failed!", 'error')
            console.log(err);
        })
    }

    return (
        <div className='user-creation-container'>
            <NotificationBox notification={notification}/>
            <p>{`Signed In as ${authUser.email}`}</p>
            <Button onClick={() => userSignOut()} text={'Sign out'}/>
        </div>
    )
}

export default SignOut;