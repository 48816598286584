import * as React from 'react';
import './UsersDisplay.css';
import { CompletedTask, Room, Task, Ticket } from '../models/models';
import Button from '../components/button/Button';
import { Accordion } from '../components/accordion/Accordion';
import { formateDate, getRoom, getTask } from '../utils';
import useConfirmModal from '../components/confirmModal/useConfirmModal';
import { ConfirmModal } from '../components/confirmModal/ConfirmModal';
import "./ticketsdisplay.css";

export interface ITicketsDisplayProps {
    rooms?: Room[];
    tasks?: Task[];
    tickets: Ticket[];
    deleteTicket?: (roomID: string) => void; 
    removeTicketItem?: (ticketID: string, roomID: string, taskID: string) => void;
}

export function TicketsDisplay ({rooms, tasks, tickets, deleteTicket, removeTicketItem}: ITicketsDisplayProps) {

    return (
        <div className="users-display">
            <div className="room-list">
                {tickets.map((t: Ticket, index: number) => (
                    <TicketDisplay ticket={t} tasks={tasks} rooms={rooms} key={"ticketID" + t.ticketID} deleteTicket={deleteTicket} removeTicketItem={removeTicketItem}/>
                ))}
            </div>
        </div>
    );
}

interface IRoomDisplayProps {
    rooms?: Room[];
    tasks?: Task[];
    ticket: Ticket;
    deleteTicket?: (roomID: string) => void;
    removeItem?: (roomID: string, taskID: string) => void;
    removeTicketItem?: (ticketID: string, roomID: string, taskID: string) => void;
}


export function TicketDisplay({rooms, tasks, ticket, deleteTicket, removeItem, removeTicketItem}: IRoomDisplayProps) {
    const {modalIsOpen, open, close} = useConfirmModal()
    const {modalIsOpen: modalIsOpen2, open: open2, close: close2} = useConfirmModal()
    return (
        <div className="room-item">
            {ticket.date !== "" && <p className='highlight-fill'>Date: {formateDate(ticket.date)}</p>}
            <p>Completed tasks:</p>
            <div className="room-member-container">
                {ticket.completedTasks.map((completedTask: CompletedTask, index: number) => (
                    <div className="list-item-container" key={"ticket" + ticket.ticketID + "-" +  completedTask.roomID + "-" + completedTask.taskID}>
                        {removeTicketItem !== undefined && <ConfirmModal text={"ticket item"} modalIsOpen={modalIsOpen2} closeModal={close2} callback={() => removeTicketItem(ticket.ticketID, completedTask.roomID, completedTask.taskID)}/>}
                        <p className="item-index">{index+1}. </p>
                        <div className='item-value-container'>
                            <p>Room: {rooms && getRoom(rooms, completedTask.roomID).roomNumber}, Task: {tasks && getTask(tasks, completedTask.taskID).taskNumber} ({tasks && getTask(tasks, completedTask.taskID).points}p)</p>
                            <p className="text-small">{tasks?.filter((task: Task) => task.taskID === completedTask.taskID).map((task: Task) => task.name)}</p>
                        </div>
                        {removeItem !== undefined && <Button onClick={() => removeItem(completedTask.roomID, completedTask.taskID)} text={'X'} theme='red-white'/>}
                        {removeTicketItem !== undefined && <Button onClick={open2} text={'X'} theme='red-white'/>}
                    </div>

                ))}
            </div>


            {ticket.ticketID !== "" && deleteTicket !== undefined && 
            <div className='delete-item-section'>
                <ConfirmModal modalIsOpen={modalIsOpen} closeModal={close} callback={() => deleteTicket(ticket.ticketID)}/>
                <Button onClick={open} text={'Delete ticket'} theme='red-white'/>
            </div>
            }
        </div>
    )
    
}
