import * as React from 'react';
import Button from './components/button/Button';

interface IPreferencesProps {
}

export const setNormalTheme = () => {
    document.documentElement.style.setProperty('--bg', 'white');
    document.documentElement.style.setProperty('--text-color-1', 'black');
    document.documentElement.style.setProperty('--text-color-2', 'black');
    document.documentElement.style.setProperty('--theme-secondary', '#6ecaf5');
    document.documentElement.style.setProperty('--red30', '#6ecaf5');
    document.documentElement.style.setProperty('--font-theme', 'Segoe UI');
    document.documentElement.style.setProperty('--font-size-percentage', '100%');
}

export const setMarioTheme = () => {
    document.documentElement.style.setProperty('--bg', '#aaa7fe');
    document.documentElement.style.setProperty('--text-color-1', 'white');
    document.documentElement.style.setProperty('--text-color-2', 'black');
    // document.documentElement.style.setProperty('--text-color-2', '#dd5918');
    document.documentElement.style.setProperty('--theme-secondary', 'white');
    document.documentElement.style.setProperty('--red30', 'white');
    document.documentElement.style.setProperty('--font-theme', 'mario');
    // document.documentElement.style.setProperty('--font-size-percentage', '50%');
}

export function Preferences (props: IPreferencesProps) {

    return (
        <div>
            <Button onClick={() => setMarioTheme()} text={'Mario theme'}/>
            <Button onClick={() => setNormalTheme()} text={'Normal theme'}/>
        </div>
    );
}
