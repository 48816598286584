import * as React from 'react';
import Button from '../components/button/Button';
import { useState } from 'react';
import { addDoc, collection, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase-config';
import useNotificaiton from '../components/notification/useNotification';
import NotificationBox from '../components/notification/NotificationBox';
import "./UsersDisplay.css";
import useTasks from './useTasks';
import { TasksDisplay } from './TasksDisplay';
import { Task } from '../models/models';
import { Accordion } from '../components/accordion/Accordion';

export interface ICreateUserProps {
}

export function CreateTask (props: ICreateUserProps) {
    const [taskNumber, setTaskNumber] = useState<number>(0);
    const [taskname, setTaskname] = useState<string>("");
    const [taskPoints, setTaskPoints] = useState<number>(0);
    const [taskDescription, setTaskDescription] = useState<string>("");
    const [taskPointsDescription, setTaskPointsDescription] = useState<string>("");

    const { notification, showTemporarily } = useNotificaiton()
    const tasksCollectionRef = collection(db, "tasks");
    const {tasks, getTasks} = useTasks();

    const createTask = async () => {
        if (tasks.some((task: Task) => task.taskNumber === taskNumber && task.points === taskPoints)) {
            showTemporarily("Task already exists", `Task: ${taskNumber}, Points: ${taskPoints}`, "error")
            return;
        }
        await addDoc(tasksCollectionRef, {taskNumber: taskNumber, name: taskname, points: taskPoints, pointsDescription: taskPointsDescription, description: taskDescription}).then(() => {
            showTemporarily("Task added", '', 'successful');
            getTasks()
        }).catch((err) => {
            console.log(err);
            showTemporarily("Failed to add task", '', 'error');
        })
    }

    const deleteTask = async (ticketID: string) => {
        await deleteDoc(doc(db, "tasks", ticketID)).then(() => {
            showTemporarily("Task deleted", '', 'successful');
            getTasks()
        }).catch((err) => {
            console.log(err);
            showTemporarily("Failed to delete task", '', 'error');
        })
    }
    
    return (
        <div className='user-creation-container'>
            <NotificationBox notification={notification}/>
            <div className="input-container">
                <input type="number" placeholder="task number..." onChange={(e) => {setTaskNumber(parseInt(e.target.value))}}/>
                <input placeholder="task name..." onChange={(e) => {setTaskname(e.target.value)}}/>
                <input type="number" placeholder="points..." onChange={(e) => {setTaskPoints(parseInt(e.target.value)); setTaskPointsDescription(e.target.value + " points")}}/>
                <input placeholder="points description..." value={taskPointsDescription} onChange={(e) => {setTaskPointsDescription(e.target.value)}}/>
                <textarea placeholder="description..." onChange={(e) => {setTaskDescription(e.target.value)}}/>
                <Button onClick={() => createTask()} text={'Create Task'}/>
            </div>

            
            <Accordion title={'Tasks in database'} children={<TasksDisplay tasks={tasks} deleteTask={deleteTask}/>}/>
            
        </div>
    );
}
