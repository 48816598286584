import { CollectionReference, DocumentData, QuerySnapshot, getDocs, getDocsFromCache } from "firebase/firestore";
import { Room, Task, User } from "./models/models";

/**
 * Format date function
 * @param zuluTime time in zulu notation (2022-04-08T10:00:00Z).
 */
export const formateDate = (date: string) => {
    const localDate = new Date(date);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const dateFormat = monthNames[localDate.getMonth()] + " " + localDate.getDay() + " " +  localDate.getHours() + ":" + localDate.getMinutes()
    return dateFormat
}

export const SortUsers = (users: User[]) => {
    return users.sort((a: User, b: User) => (''+a.firstname).localeCompare(b.firstname))
}

export const getRoom = (rooms: Room[], roomID: string) => {
    const filteredRooms = rooms.filter((r: Room) => r.roomID === roomID)
    return filteredRooms.length > 0 ? filteredRooms[0] : {roomID: "-1", roomNumber: -1, nickname: ""}
}

export const getTask = (tasks: Task[], taskID: string) => {
    
    const filteredTasks = tasks.filter((t: Task) => t.taskID === taskID)
    return filteredTasks.length > 0 ? filteredTasks[0] : {taskID: "-1", taskNumber: -1, points: -1}
}

export const taskCompare = (a: Task, b: Task) => {
    if (a.taskNumber === b.taskNumber) {
        return a.points - b.points;
    } else {
        return a.taskNumber - b.taskNumber
    }
}

const USE_CACHE = true; // Global cache option
/**
 * Get data from firebase database
 * @param collectionRef collection ref
 * @param useCache local cache option, can be overwritten to false
 * @returns 
 */
export const getFirebaseData = async (collectionRef: CollectionReference<DocumentData, DocumentData>, useCacheLocal: boolean = false) => {
    if (!USE_CACHE || !useCacheLocal) {
        const data = await getDocs(collectionRef);
        console.log("Force no cache", collectionRef.path, data.docs.length);
        return data;
    }

    let data;
    try {
        data = await getDocsFromCache(collectionRef);
        if (data.docs.length === 0){
            throw new Error('Cache empty, try server');
        }
        console.log("Cached document data:", collectionRef.path, data.docs.length);
    } catch (e) {
        data = await getDocs(collectionRef);
        console.log("Error getting cached document:", e);
    }
    return data;
}