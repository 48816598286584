import * as React from 'react';
import Button from '../components/button/Button';
import { useState } from 'react';
import { addDoc, arrayRemove, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import useNotificaiton from '../components/notification/useNotification';
import { CompletedTask, Room, Task, Ticket} from '../models/models';
import NotificationBox from '../components/notification/NotificationBox';
import "./UsersDisplay.css";
import useRooms from './useRooms';
import { TicketDisplay, TicketsDisplay } from './TicketsDisplay';
import useTasks from './useTasks';
import useTickets from './useTickets';
import { Accordion } from '../components/accordion/Accordion';
import { taskCompare } from '../utils';

export interface ICreateTicketProps {
}

export function CreateTicket(props: ICreateTicketProps) {
    const { notification, showTemporarily } = useNotificaiton()
    const ticketCollectionRef = collection(db, "tickets");
    const [roomID, setRoomID] = useState<string>("default");
    const [taskID, setTaskID] = useState<string>("default");
    const {tickets, getTickets} = useTickets();
    const {rooms} = useRooms();
    const {tasks} = useTasks();
    const [newTicket, setNewTicket] = useState<Ticket>({completedTasks: [],date: "",ticketID: ""});

    const TicketItemAlreadyExists = (tickets: Ticket[], ticket: Ticket): [boolean, string, string] => {
        for (const newTicketct of ticket.completedTasks) {
            for (const ticket of tickets) {
                for (const ticketct of ticket.completedTasks) {
                    if (ticketct.roomID === newTicketct.roomID && ticketct.taskID === newTicketct.taskID) {
                        return [true, newTicketct.roomID, newTicketct.taskID];
                    }
                }
            }
        }
        return [false, "", ""];
    }

    const createTicket = async () => {
        const ticketAlreadyExist: [boolean, string, string] = TicketItemAlreadyExists(tickets, newTicket)
        if (ticketAlreadyExist[0]) {
            showTemporarily("Some ticket item is already added", "Room: " + ticketAlreadyExist[1] + ", Task " + ticketAlreadyExist[2], "error")
            return;
        }
        
        await addDoc(ticketCollectionRef, {date: new Date().toISOString(), completedTasks: newTicket.completedTasks}).then(() => {
            clearTicket()
            showTemporarily("Ticket added", '', 'successful');
            getTickets()
        }).catch((err) => {
            console.log(err);
            showTemporarily("Failed to add ticket", '', 'error');
        })
    }

    const deleteTicket = async (ticketID: string) => {
        await deleteDoc(doc(db, "tickets", ticketID)).then(() => {
            showTemporarily("Ticket deleted", '', 'successful');
            getTickets()
        }).catch((err) => {
            console.log(err);
            showTemporarily("Failed to delete ticket", '', 'error');
        })
    }

    const AddItem = () => {
        if (newTicket.completedTasks.some((ct: CompletedTask) => ct.roomID === roomID && ct.taskID === taskID)) {
            showTemporarily("Item already in list", '', 'warning');
            return;
        }

        setNewTicket((prev) => {
            if (prev !== undefined && taskID !== undefined && roomID !== undefined) {
                const ct: CompletedTask = {roomID: roomID, taskID: taskID}
                const t: Ticket = {...prev, completedTasks: [...prev.completedTasks, ct]};
                return t;
            }
            return prev;
        })
    }

    const removeItem = (roomID: string, taskID: string) => {
        setNewTicket((prev) => ({...prev, completedTasks: prev.completedTasks.filter((ct: CompletedTask) => !(ct.roomID === roomID && ct.taskID === taskID))}))
    }

    const deleteItemDB = async (ticketID: string, roomID: string, taskID: string) => {
        await updateDoc(doc(db, "tickets", ticketID), {completedTasks: arrayRemove({roomID: roomID, taskID: taskID})}).then(() => {
            showTemporarily("Ticket deleted", '', 'successful');
            getTickets();
        }).catch((err) => {
            console.log(err);
            showTemporarily("Failed delete ticket item", '', 'error');
        })
    }

    const clearTicket = () => {
        setRoomID("default");
        setTaskID("default");
        setNewTicket({completedTasks: [],date: "",ticketID: ""})
        showTemporarily("Cleared ticket", '', 'successful');
    }
    
    return (
        <div className='user-creation-container'>
            <NotificationBox notification={notification}/>
            <div className="input-container">

                <select onChange={(e) => setRoomID(e.target.value)} value={roomID}>
                    <option value="default" disabled>Choose here</option>
                        {rooms.sort((a: Room, b: Room) => a.roomNumber - b.roomNumber).map((room) => {
                            const nickname: string = room.nickname === "" || room.nickname === undefined ? "" : "- " + room.nickname;
                            return (<option key={"ticket-room-option" + room.roomID} value={room.roomID}>Room {room.roomNumber} {nickname}</option>)
                        })}
                </select>
                <select onChange={(e) => setTaskID(e.target.value)} value={taskID}>
                    <option value="default" disabled>Choose here</option>
                        {tasks.sort(taskCompare).map((task) => {
                            return (<option key={"ticket-task-option" + task.taskID} value={task.taskID}>{task.taskNumber} ({task.points} points) - {task.name}</option>)
                        })}
                </select>
                <Button onClick={() => AddItem()} text={'Add item'}/>
                <Button onClick={() => clearTicket()} text={'Clear ticket'}/>
                <Button onClick={() => createTicket()} text={'Create ticket'}/>
            </div>
            <div className='fit-content'>
                {newTicket !== undefined && <TicketDisplay tasks={tasks} ticket={newTicket} removeItem={removeItem}/>}
            </div>
            
            <Accordion title={'Tickets in database'} children={<TicketsDisplay rooms={rooms} tasks={tasks} tickets={tickets} removeTicketItem={deleteItemDB} deleteTicket={deleteTicket}/>}/>
            
        </div>
    );
}
