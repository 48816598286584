import * as React from 'react';

export interface IFooterProps {
}

export function Footer (props: IFooterProps) {
  return (
    <div className="footer">
        <div className='footer-content'>
            <div className='footer-description'>
                <p>Snowtrip 2024</p>
                <p>Organized by Drop, Spin and Avalanche</p>

            </div>
            <div className='johan-plug'>
                Made with 
                <div className='icon-container'>
                    <svg fill="#000000"  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M14 20.408c-.492.308-.903.546-1.192.709-.153.086-.308.17-.463.252h-.002a.75.75 0 01-.686 0 16.709 16.709 0 01-.465-.252 31.147 31.147 0 01-4.803-3.34C3.8 15.572 1 12.331 1 8.513 1 5.052 3.829 2.5 6.736 2.5 9.03 2.5 10.881 3.726 12 5.605 13.12 3.726 14.97 2.5 17.264 2.5 20.17 2.5 23 5.052 23 8.514c0 3.818-2.801 7.06-5.389 9.262A31.146 31.146 0 0114 20.408z"/></svg>
                </div> 
                by <a href="https://www.dingdangdongen.nl/" target="_blank" rel="noreferrer">Johan</a>
            </div>

        </div>
    </div>
  );
}
