import * as React from 'react';
import './UsersDisplay.css';
import { Room, User } from '../models/models';
import Button from '../components/button/Button';
import "./RoomsDisplay.css";
import { ConfirmModal } from '../components/confirmModal/ConfirmModal';
import useConfirmModal from '../components/confirmModal/useConfirmModal';
import { useState } from 'react';

export interface IRoomsDisplayProps {
    rooms: Room[];
    users: User[];
    deleteRoom?: (roomID: string) => void;
    updateNickname?: (roomID: string, nickname: string) => void;
}

export function RoomsDisplay ({rooms, users, deleteRoom, updateNickname}: IRoomsDisplayProps) {

    return (
        <div className="users-display">
            <div className="room-list">
                {rooms.sort((a: Room, b: Room) => a.roomNumber - b.roomNumber).map((r: Room, index: number) => (
                    <RoomDisplay room={r} users={users} key={"roomID" + r.roomID} deleteRoom={deleteRoom} updateNickname={updateNickname}/>
                ))}
            </div>
        </div>
    );
}

interface IRoomDisplayProps {
    room: Room;
    users: User[];
    deleteRoom?: (roomID: string) => void;
    updateNickname?: (roomID: string, nickname: string) => void;
    removeMember?: (userID: string) => void;
}


export function RoomDisplay({room, users, deleteRoom, updateNickname, removeMember}: IRoomDisplayProps) {
    const { modalIsOpen, open, close } = useConfirmModal()
    const [nickname, setNickname] = useState<string>();
    return (
        <div className="room-item">
            <div className='row start'>
                <p className="highlight-fill">Room: {room.roomNumber}{room.nickname !== "" && " - " + room.nickname}</p>
                {/* <p>{room.nickname !== "" && "aka " + room.nickname}</p> */}
            </div>
            <p>Room members:</p>
            <div className="room-member-container">
                {room.members.map((userID: string, index: number) => (
                    <div className="room-member" key={"roomuser" + userID}>
                        <p className="room-user-item">{index+1}. {users.filter((u: User) => u.userID === userID).map((u: User) => (u.firstname + " " + u.lastname))}</p>
                        {removeMember !== undefined && <Button onClick={() => removeMember(userID)} text={'X'} theme='red-white'/>}
                    </div>
                ))}
            </div>
            {room.roomID !== "" && deleteRoom !== undefined && 
            <>
                <input placeholder='New nickname...' value={nickname} onChange={(e) => {setNickname(e.target.value)}}/>
                {updateNickname !== undefined && nickname !== undefined && nickname.length > 0 && <Button onClick={() => {updateNickname(room.roomID, nickname); setNickname("")}} text={'Update nickname'}/>}
                <ConfirmModal modalIsOpen={modalIsOpen} closeModal={close} callback={() => deleteRoom(room.roomID)}/>
                <Button onClick={open} text={'Delete room'} theme='red-white'/>
            </>
            }
        </div>
    )
    
}
