import './modal.css';
import * as React from 'react';
import Modal from 'react-modal';
import Button from '../button/Button';

export interface ITableViewProps {
    modalIsOpen: boolean;
    closeModal: () => void;
    text?: string;
    callback: any;
}
Modal.setAppElement('#root');

/**
 * Confirm modal displays text with two button. 1 button to cancel and close the modal,
 * and 1 button to execute the callback function.
 * @param modalIsOpen whether the modal should be visible
 * @param close function to close the modal
 * @param text optional te add extra text to the modal dialog
 * @param callback function that is executed when the button is clicked
 */
export function ConfirmModal({
    modalIsOpen,
    closeModal,
    text,
    callback,
}: ITableViewProps): JSX.Element {
    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => {
                closeModal();
            }}
            style={{
                content: {
                    width: '50%',
                    height: '20vh',
                    top: '10%',
                    left: '50%',
                    transform: 'translate(-50%, -10%)',
                    borderRadius: '1rem',
                },
            }}
        >
            <div className="confirm-delete-container">
                <p>Are you sure you want to delete this {text}</p>
                <div className="dialog-buttons">
                    <Button
                        onClick={() => {
                            closeModal();
                        }}
                        text={'Cancel'}
                    />
                    <Button
                        onClick={() => {
                            if (callback !== null) {
                                void callback();
                            }
                            closeModal();
                        }}
                        theme={'red-white'}
                        text={'Delete'}
                    />
                </div>
            </div>
        </Modal>
    );
}