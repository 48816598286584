import * as React from 'react';
import Modal from "react-modal";
import useWindowDimensions from '../../useWindowDimensions';
import "./mymodal.css";

export interface IMyModalProps {
    title: string;
    children: JSX.Element;
    modelIsOpen: boolean;
    closeModal: (active: boolean) => void;
}

export function MyModal ({ title, children, modelIsOpen, closeModal }: IMyModalProps) {
    const {width} = useWindowDimensions()
    
    return (
        <Modal
            isOpen={modelIsOpen}
            closeTimeoutMS={200}
            style={{
                content: {
                    width: width > 600 ? "50%" : '100%',
                    height: '70vh',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '1rem',
                    backgroundColor: 'var(--bg)',
                },
            }}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h2>{title}</h2>

                    <button
                        className="close"
                        onClick={() => {
                            closeModal(false);
                        }}
                    >
                        &#x2715;
                    </button>
                </div>

                <div>
                    {children}
                </div>
            </div>
    </Modal>
    );
}
