import * as React from 'react';
import Button from '../components/button/Button';
import { useState } from 'react';
import { addDoc, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import useNotificaiton from '../components/notification/useNotification';
import { Room } from '../models/models';
import NotificationBox from '../components/notification/NotificationBox';
import "./UsersDisplay.css";
import { RoomDisplay, RoomsDisplay } from './RoomsDisplay';
import useUsers from './useUsers';
import useRooms from './useRooms';
import { Accordion } from '../components/accordion/Accordion';
import { SortUsers } from '../utils';

export interface ICreateRoomProps {
}

export function CreateRoom (props: ICreateRoomProps) {
    const { notification, showTemporarily } = useNotificaiton()
    const roomsCollectionRef = collection(db, "rooms");
    const [newRoom, setNewRoom] = useState<Room>({roomNumber: 0, nickname: "", members:[], roomID: ""});
    const [userID, setUserID] = useState<string>("default");
    const {users} = useUsers();
    const {rooms, getRooms} = useRooms();

    const createRoom = async () => {
        if (newRoom.members.length === 0) {
            showTemporarily("Failed to add room - no room members", '', 'error');
            return;
        }
        if (rooms.some((room: Room) => room.roomNumber === newRoom.roomNumber)) {
            showTemporarily("Failed to add room", "room " + newRoom.roomNumber + " already exists" , 'error');
            return;
        }
        // TODO check if room members dont already exist in other rooms

        await addDoc(roomsCollectionRef, {members: newRoom.members, roomNumber: newRoom.roomNumber, nickname: newRoom.nickname}).then(() => {
            showTemporarily("User added", '', 'successful');
            clearRoom()
            getRooms()
        }).catch((err) => {
            console.log(err);
            showTemporarily("Failed to add user", '', 'error');
        })
    }

    const updateNickname = async (roomID: string, nickname: string) => {
        await updateDoc(doc(db, "rooms", roomID), {nickname: nickname}).then(() => {
            showTemporarily("Nickname updated", '', 'successful');
            getRooms()
        }).catch((err) => {
            console.log(err);
            showTemporarily("Failed to update nickname", '', 'error');
        })
    }

    const deleteRoom = async (roomID: string) => {
        
        await deleteDoc(doc(db, "rooms", roomID)).then(() => {
            showTemporarily("Room deleted", '', 'successful');
            getRooms()
        }).catch((err) => {
            console.log(err);
            showTemporarily("Failed to delete room", '', 'error');
        })
    }

    const AddPerson = () => {
        if (userID === "default") {
            showTemporarily("No user to add", '', 'warning');
            return;
        }
        if (userID !== undefined && newRoom.members.includes(userID)) {
            showTemporarily("User already in room", '', 'warning');
            return;
        }

        setNewRoom((prev) => {
            if (prev !== undefined && userID !== undefined) {
                const r: Room = {...prev, members: [...prev.members, userID]};
                return r;
            }
            return prev;
        })
    }

    const removePerson = (userID: string) => {
        setNewRoom((prev) => {
            if (prev !== undefined && userID !== undefined) {
                const r: Room = {...prev, members: prev.members.filter((uID: string) => uID !== userID)};
                return r;
            }
            return prev;
        })
    }

    const clearRoom = () => {
        setNewRoom({roomNumber: 0, nickname: "", members:[], roomID: ""});
        setUserID("default");
        showTemporarily("cleared room", '', 'successful');
    }
    
    return (
        <div className='user-creation-container'>
            <NotificationBox notification={notification}/>
            <div className="input-container">
                <input type='number' value={newRoom.roomNumber} placeholder="room number..." onChange={(e) => {setNewRoom((prev) => ({...prev, roomNumber: parseInt(e.target.value)}))}} />
                <input placeholder="nickname..." value={newRoom.nickname} onChange={(e) => {setNewRoom((prev) => ({...prev, nickname: e.target.value}))}}/>
                <select onChange={(e) => setUserID(e.target.value)} value={userID}>
                    <option value="default" disabled>Choose here</option>
                        {SortUsers(users).map((user) => {
                            return (<option key={"option" + user.userID} value={user.userID}>{user.firstname} {user.lastname}</option>)
                        })}
                </select>
                <Button onClick={() => AddPerson()} text={'Add person'}/>
                <Button onClick={() => clearRoom()} text={'Clear room'}/>
                <Button onClick={() => createRoom()} text={'Create Room'}/>
            </div>

            <div className='fit-content'>
                {newRoom !== undefined && <RoomDisplay room={newRoom} users={users} deleteRoom={(roomID: string) => {deleteRoom(roomID)}} removeMember={(userID: string) => removePerson(userID)}/>}
            </div>
            <Accordion title={'Rooms in database'} children={<RoomsDisplay rooms={rooms} users={users} deleteRoom={(roomID: string) => {deleteRoom(roomID)}} updateNickname={updateNickname}/>}/>
        </div>
    );
}
