import { collection, getDocs, getDocsFromCache } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../firebase-config';
import { Ticket } from '../models/models';
import { getFirebaseData } from '../utils';


export default function useTickets(): {tickets: Ticket[]; getTickets: () => void} {
    
    const ticketsCollectionRef = collection(db, "tickets");
    const [tickets, setTickets] = useState<Ticket[]>([]);

    const getTickets = async () => {
        const data = await getFirebaseData(ticketsCollectionRef, false)
        const dataTickets: any = data.docs.map((doc) => ({...doc.data(), id: doc.id}))
        setTickets(dataTickets.map((ticket: any) => {
            const t: Ticket = {date: ticket.date, ticketID: ticket.id, completedTasks: ticket.completedTasks};
            return t;
        }))
    }

    useEffect(() => {
        getTickets();
    }, [])
    
    return {tickets, getTickets};
}