import React, { useEffect, useState } from 'react';
import './App.css';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase-config';
import SignIn from './SignIn';
import useNotificaiton from './components/notification/useNotification';
import NotificationBox from './components/notification/NotificationBox';
import SignOut from './SignOut';
import { CreateTicket } from './wite/CreateTicket';
import { CreateUser } from './wite/CreateUser';
import { CreateRoom } from './wite/CreateRoom';
import { CreateTask } from './wite/createTask';
import { SideBar } from './components/sidebar/SideBar';
import { Header } from './Header';
import { LeaderBoard } from './Leaderboard';
import { TasksDisplay } from './wite/TasksDisplay';
import useTasks from './wite/useTasks';
import useRooms from './wite/useRooms';
import { RoomsDisplay } from './wite/RoomsDisplay';
import useUsers from './wite/useUsers';
import VectorSVG from "./Vector.svg"
import { Preferences, setMarioTheme, setNormalTheme } from './Preferences';
import { Footer } from './Footer';
import { PDFViewer } from './components/pdfViewer/PDFVIewer';
import { Accordion } from './components/accordion/Accordion';
import { Crazy88Description } from './Crazy88Description';


export type Screen = "home" | "login" | "rooms" | "tasks" | "booklet";

function App() {
    const [authUser, setAuthUser] = useState<any>(null);
    const { notification, showTemporarily } = useNotificaiton()
    const [sidebar, setSidebar] = useState(false);
    const [screen, setScreen] = useState<Screen>('home')
    const {tasks} = useTasks();
    const {rooms} = useRooms();
    const {users} = useUsers();

    const showSidebar = () => setSidebar(!sidebar);

    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
            } else {
                setAuthUser(null)
            }
        })

        return () => {
            listen();
        }
    }, []);

    useEffect(() => {
        setMarioTheme()
    }, [])

    const handleSetScreen = (screen: Screen) => {
        setSidebar(false);
        setScreen(screen);
    }
    
    return (
        <div className="App">
            <div className="page-container">
                <NotificationBox notification={notification}/>
                <Header children={

                    <button className="hamburger-menu" type="button" onClick={() => showSidebar()}>
                        {!sidebar && <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>}
                        {sidebar && <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 329.26933 329" width="16"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/></svg>}
                     </button>
                    }
                />
                
            <SideBar active={sidebar} activeScreen={screen} setScreen={(screen: Screen) => handleSetScreen(screen)}/>
            {screen === "home" && 
            <>
                <Accordion type="small" title={'Learn more'} children={<Crazy88Description setScreen={(screen: Screen) => handleSetScreen(screen)}/>}/>
                <div className="leaderboard-wrapper"><LeaderBoard tickets1={[]} rooms={rooms} tasks={tasks}/></div>
            </>
            }
            {screen === "tasks" && <div className='task-screen-containter'><TasksDisplay tasks={tasks} descriptive={true}/></div>}
            {screen === "rooms" && <RoomsDisplay rooms={rooms} users={users}/>}
            {screen === "booklet" && <PDFViewer/>}
            


                {
                    screen === "login" ? !authUser ? 
                    <SignIn/> : 
                    <>
                        <div className="create-container">
                            <SignOut authUser={authUser}/>
                            <Preferences/>
                            <CreateTicket/>
                            <CreateTask/>
                            <CreateRoom/>
                            <CreateUser/>
                        </div>
                    </> : <></>
                }
            </div>
            <Footer/>
        </div>
    );
}

export default App;
