import * as React from 'react';
import { Screen } from './App';
import styled from 'styled-components';

export interface ICrazy88DescriptionProps {
    setScreen: (screen: Screen) => void;
}

export function Crazy88Description ({setScreen}: ICrazy88DescriptionProps) {
  return (
    <Container>
      <p>
        What is a snowtrip without a crazy 88? We provide this years <b>88 challenges</b> for which you have one weeks time to complete as many as possible. <b>Every room makes up one team</b>. You can <b>score points by completing these</b> <button className="button-none" onClick={() => setScreen("tasks")}><b>challenges</b></button> as a team. If your room ends up with the most points, you will win one <b>sick prize</b>!
        </p>
        <i>This year, all members of the winning room will receive free <a href='https://nskfreestyle.nl/' target='_blank' rel="noreferrer">NSK</a> tickets worth &euro;12.99 each!!</i>
        <p className='text-small'>
            IMPORTANT! We need video proof of every challenge in order to give you points! Videos can be send in the Whatsapp groupchat, and don't forget to mention the challenge number in video that you send (otherwise you may not get points). Alternatively you can submit proof of your challenges by uploading your videos in the <a href="https://drive.google.com/drive/folders/1AvCerjkW46z49t5gQYT1W6xz5_j2z_PR?usp=sharing" target='_blank' rel="noreferrer">drive folder</a> of your team.
            <br></br>
            For better quality, please film everything in landscape, and not vertically, such that it does not look like it was filmed on a potato by a potato. Uploading content will of course be confidential! (in case of sexy time and stuff)</p>
    </Container>
  );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-right: 0.2rem;
`
