import { collection, getDocs } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../firebase-config';
import { User } from '../models/models';
import { getFirebaseData } from '../utils';


export default function useUsers(): {users: User[]; getUsers: () => void} {
    
    const usersCollectionRef = collection(db, "users");
    const [users, setUsers] = useState<User[]>([]);

    const getUsers = async () => {
        // const data = await getDocs(usersCollectionRef);
        const data = await getFirebaseData(usersCollectionRef, true)
        const dataUsers: any = data.docs.map((doc) => ({...doc.data(), id: doc.id}))
        setUsers(dataUsers.map((user: any) => {
            const u: User = {userID: user.id, firstname: user.firstname, lastname: user.lastname, authID: user.authID};
            return u;
        }))
    }

    useEffect(() => {
        getUsers();
    }, [])
    
    return {users, getUsers};
}