import { useState } from 'react';

/**
 */
export default function useConfirmModal(): {
    modalIsOpen: boolean,
    open: () => void;
    close: () => void;
} {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const open = (): void => {
        setModalIsOpen(true);
    };

    const close = (): void => {
        setModalIsOpen(false);
    }

    return { modalIsOpen, open, close };
}