import * as React from 'react';
import { useState } from 'react';
import "./accordion.css";

interface IAccordionProps {
    title: string;
    children: JSX.Element;
    type?: string
}

export function Accordion ({title, children, type="big"}: IAccordionProps) {

    const [selected, setSelected] = useState<boolean>(false);

    return (
        <div className="wrapper">
            <div className="accordion">
                <div onClick={() => setSelected((prev) => !prev)} className={type === "small" ? 'accordion-header-small' : "accordion-header"}>
                    <h1 className="accordion-title">{title}</h1>
                    <span>{selected ? "-" : "+"}</span>
                </div>
                <div className={selected ? "accordion-content show" : "accordion-content"}>
                    {children}
                </div>
            </div>
        </div>
    );
}

interface IAccordionJSXProps {
    header: JSX.Element;
    children: JSX.Element;
}

export function AccordionJSX ({header, children}: IAccordionJSXProps) {

    const [selected, setSelected] = useState<boolean>(false);

    return (
        <div className="wrapper">
            <div className="accordion-jsx">
                <div onClick={() => setSelected((prev) => !prev)} className='accordion-header-jsx'>
                    {header}
                    <span className='selected-icon'>{selected ? "-" : "+"}</span>
                </div>
                <div className={selected ? "accordion-content show" : "accordion-content"}>
                    {children}
                </div>
            </div>
        </div>
    );
}
