import { collection, getDocs } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../firebase-config';
import { Room } from '../models/models';
import { getFirebaseData } from '../utils';


export default function useRooms(): {rooms: Room[]; getRooms: () => void} {
    const roomsCollectionRef = collection(db, "rooms");
    const [rooms, setRooms] = useState<Room[]>([]);

    const getRooms = async () => {
        // const data = await getDocs(roomsCollectionRef);
        const data = await getFirebaseData(roomsCollectionRef, false)
        const dataRooms: any = data.docs.map((doc) => ({...doc.data(), id: doc.id}))
        setRooms(dataRooms.map((room: any) => {
            const r: Room = {roomNumber: room.roomNumber, members: room.members, nickname: room.nickname, roomID: room.id};
            return r;
        }))
    }

    useEffect(() => {
        getRooms();
    }, [])
    
    return {rooms, getRooms};
}