import { collection, getDocs } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../firebase-config';
import { Task } from '../models/models';
import { getFirebaseData } from '../utils';


export default function useTasks(): {tasks: Task[]; getTasks: () => void} {
    
    const tasksCollectionRef = collection(db, "tasks");
    const [tasks, setTasks] = useState<Task[]>([]);

    const getTasks = async () => {
        // const data = await getDocs(tasksCollectionRef);
        const data = await getFirebaseData(tasksCollectionRef, true)
        const dataTasks: any = data.docs.map((doc) => ({...doc.data(), id: doc.id}))
        setTasks(dataTasks.map((task: any) => {
            const t: Task = {taskID: task.id, name: task.name, description: task.description, points: task.points, pointsDescription: task.pointsDescription, taskNumber: task.taskNumber};
            return t;
        }))
    }

    useEffect(() => {
        getTasks();
    }, [])
    
    return {tasks, getTasks};
}