import * as React from 'react';

export interface IHeaderProps {
    children: JSX.Element;
}

export function Header ({children}: IHeaderProps) {
    return (
        <div className="header">
            <h1 className='header-title'>Crazy 88</h1>
            {children}
        </div>
    );
}
