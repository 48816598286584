import * as React from 'react';
import './leaderboard.css';
import { useEffect, useState } from 'react';
import { Room, Task, Ticket } from './models/models';
import useTickets from './wite/useTickets';
import Modal from "react-modal";
import useUsers from './wite/useUsers';
import { RoomDisplay } from './wite/RoomsDisplay';
import { TasksDisplay } from './wite/TasksDisplay';
import { MyModal } from './components/modal/MyModal';
import { getRoom } from './utils';

Modal.setAppElement('#root');

export interface LeaderBoardItem {
    roomID: string;
    points: number;
}

const aggregate = (tickets: Ticket[], rooms: Room[], tasks: Task[]) => {
    const leaderboard: LeaderBoardItem[] = []
    for (const ticket of tickets) {
        for (const completedTask of ticket.completedTasks) {
            const task: Task[] = tasks.filter((task: Task) => task.taskID === completedTask.taskID)
            const taskPoints: number = task.length === 0 ? 0 : task[0].points

            if (leaderboard.some((el: LeaderBoardItem) => el.roomID === completedTask.roomID)) {
                for (const leaderboardItem in leaderboard) {
                    if (leaderboard[leaderboardItem].roomID === completedTask.roomID) {
                        leaderboard[leaderboardItem].points += taskPoints;
                    }
                  }
            } else {
                leaderboard.push({roomID: completedTask.roomID, points: taskPoints})
            }
        }
    }
    
    return leaderboard
}

/**
 * Return the completed tasks of a given room
 * @param tickets 
 * @param roomID 
 * @param tasks 
 */
const getCompletedTasks = (tickets: Ticket[], roomID: string, tasks: Task[]): Task[] => {
    const completedTasks: Task[] = []
    for (const ticket of tickets) {
        for (const completedTask of ticket.completedTasks) {
            if (completedTask.roomID === roomID) {
                const task: Task[] = tasks.filter((task: Task) => task.taskID === completedTask.taskID)
                completedTasks.push(...task);
            }
        }
    }
    return completedTasks;
}

interface ILeaderBoardProps {
    tickets1: Ticket[];
    rooms: Room[];
    tasks: Task[];
}

const getMaxPoints = (leaderboard: LeaderBoardItem[]) => {
    return Math.max(...leaderboard.map((item: LeaderBoardItem) => item.points))

}
export function LeaderBoard ({tickets1, rooms, tasks}: ILeaderBoardProps) {
    const [modelIsOpen, setModalIsOpen] = useState(false);
    const {tickets} = useTickets();
    const {users} = useUsers();
    const [leaderboard, setLeaderboard] = useState<LeaderBoardItem[]>(aggregate(tickets, rooms, tasks));
    const [roomID, setRoomID] = useState<string>("mNN9qL9ngYmMB48yIhOl");
    const [roomDisplay, SetRoomDisplay] = useState<string>("numbers");

    useEffect(() => {
        setLeaderboard(aggregate(tickets, rooms, tasks).sort((a: LeaderBoardItem, b: LeaderBoardItem) => b.points - a.points))
    }, [tickets, rooms, tasks])

    // console.log("leaderboard",leaderboard)

    return (
        <>
            <MyModal 
                title={'Room information'} 
                children={
                    <div className='room-info'>
                        {rooms.length > 0 && <RoomDisplay room={rooms.filter((r: Room) => r.roomID === roomID)[0]} users={users}/>}
                        <p className='highlight-fill fit-content'>Total points: {(leaderboard.length > 0 && leaderboard.filter((item: LeaderBoardItem) => item.roomID === roomID).length > 0) && leaderboard.filter((item: LeaderBoardItem) => item.roomID === roomID)[0].points}</p>
                        <div>
                            <p>Completed tasks:</p>
                            <TasksDisplay tasks={getCompletedTasks(tickets, roomID, tasks)}/>
                        </div>
                    </div>
                    } 
                modelIsOpen={modelIsOpen} 
                closeModal={setModalIsOpen}/>

            <div className='leaderboard-header'>
                <div className='leaderboard-subheader'>
                    <div className='major-stats'>
                        <div className='row'><p className='highlight-1'>Total submissions:</p><p>{tickets.reduce((acc, curr) => acc + curr.completedTasks.length, 0)}</p></div>
                    </div>
                    <select onChange={(e) => SetRoomDisplay(e.target.value)} value={roomDisplay}>
                    <option value="default" disabled>Choose here</option>
                        <option key="rNames" value="names">Room names</option>
                        <option key="rNumbers" value="numbers">Room numbers</option>
                </select>
                </div>
            </div>
            <div className="leaderboard-container">
                {leaderboard.map((leaderboardItem, idx) => (
                    
                    <div className="leaderboard-item" key={"leaderboard-item" + leaderboardItem.roomID}>
                        {roomDisplay==="numbers" && <p className='leaderboard-username' onClick={() => {setModalIsOpen(true); setRoomID(leaderboardItem.roomID)}}>{idx+1}. Room {getRoom(rooms, leaderboardItem.roomID).roomNumber }</p>}
                        {roomDisplay==="names" && <p className='leaderboard-username' onClick={() => {setModalIsOpen(true); setRoomID(leaderboardItem.roomID)}}>{idx+1}. {getRoom(rooms, leaderboardItem.roomID).nickname }</p>}
                        <div className="progress">
                            <div className="progress-fill" style={{width: `${(leaderboardItem.points * 100) / getMaxPoints(leaderboard)}%`}}></div>
                            <span className="progress-text">{leaderboardItem.points} points</span>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
