import * as React from 'react';
import Button from '../components/button/Button';
import { useState } from 'react';
import { addDoc, collection, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase-config';
import useNotificaiton from '../components/notification/useNotification';
import { UsersDisplay } from './UsersDisplay';
import NotificationBox from '../components/notification/NotificationBox';
import "./UsersDisplay.css";
import useUsers from './useUsers';

export interface ICreateUserProps {
}

export function CreateUser (props: ICreateUserProps) {
    const [firstname, setFirstname] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const { notification, showTemporarily } = useNotificaiton()
    const usersCollectionRef = collection(db, "users");
    const {users, getUsers} = useUsers();

    const createUser = async () => {
        if (firstname.trim() === "" || lastname.trim() === "") {
            showTemporarily("Failed to add user - add name", 'error');
            return;
        }
        await addDoc(usersCollectionRef, {firstname: firstname, lastname: lastname, authID: "none"}).then(() => {
            showTemporarily("User added", '', 'successful');
            getUsers()
        }).catch((err) => {
            console.log(err);
            showTemporarily("Failed to add user", '', 'error');
        })
    }

    const deleteUser = async (userID: string) => {
        await deleteDoc(doc(db, "users", userID)).then(() => {
            showTemporarily("User deleted", '', 'successful');
            getUsers()
        }).catch((err) => {
            console.log(err);
            showTemporarily("Failed to delete user", '', 'error');
        })
    }
    
    return (
        <div className='user-creation-container'>
            <NotificationBox notification={notification}/>
            <div className="input-container">
                <input placeholder="firstname..." onChange={(e) => {setFirstname(e.target.value)}}/>
                <input placeholder="lastname..." onChange={(e) => {setLastname(e.target.value)}}/>
                <Button onClick={() => createUser()} text={'Create User'}/>
            </div>
            <UsersDisplay users={users} deleteUser={deleteUser}/>
        </div>
    );
}
