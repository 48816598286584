import * as React from 'react';
import './UsersDisplay.css';
import { Task } from '../models/models';
import Button from '../components/button/Button';
import useConfirmModal from '../components/confirmModal/useConfirmModal';
import { ConfirmModal } from '../components/confirmModal/ConfirmModal';
import "./ticketsdisplay.css";
import styled from 'styled-components';
import { taskCompare } from '../utils';

export interface ITasksDisplayProps {
    tasks: Task[];
    descriptive?: boolean;
    deleteTask?: (taskID: string) => void;
}


/**
 * Returns a task list where each task number only appears once. It might be that there are tasks with the same task number but with different points assigned
 * @example [{taskNumber: 1, points: 5, ...}, {taskNumber: 1, points: 10, ...}] returns [{taskNumber: 1, points: 5, ...}]
 * @param tasks 
 */
const getMajorTasks = (tasks: Task[]): Task[] => {
    const sortedTasks = tasks.sort(taskCompare);
    const result = []
    const taskNumbers = new Set();
    for (const task of sortedTasks) {
        if (!taskNumbers.has(task.taskNumber)) {
            result.push(task);
        }
        taskNumbers.add(task.taskNumber)
    }
    return result;
}

export function TasksDisplay ({tasks, deleteTask, descriptive = false}: ITasksDisplayProps) {

    const {modalIsOpen, open, close} = useConfirmModal();

    return (
        <div className="users-display">
            <div className="user-list">
                {getMajorTasks(tasks).map((t: Task, index: number) => (
                    <div key={"taskID" + t.taskID} className='list-item-container'>
                        <p className='item-index'>{t.taskNumber}.</p>
                        {descriptive ? 
                        <div>
                            <p>({t.pointsDescription}) {t.name + "."}</p>
                            {t.description.length !== 0 && <LinkWrap href={t.description} target='_blank' rel="noreferrer">Example</LinkWrap>}
                            {/* <p>{t.pointsDescription}</p> */}
                        </div>:
                        
                        <div>
                            <p>({t.points} points) {t.name + "."}</p>
                            {t.description.length !== 0 && <LinkWrap href={t.description} target='_blank' rel="noreferrer">Example</LinkWrap>}
                        </div>
                        }
                        
                        {deleteTask !== undefined && 
                        <div className='delete-section-row'>
                            <ConfirmModal modalIsOpen={modalIsOpen} closeModal={close} callback={() => deleteTask(t.taskID)} />
                            <Button onClick={() => open()} text={'Delete task'} theme='red-white'/>
                        </div>
                        }
                    </div>
                ))}
            </div>
        </div>
    );
}

const LinkWrap = styled.a`
    /* overflow-x: hidden; */
    /* width: 100%; */
    /* border: 1px solid white; */
    /* word-wrap:break-word; */
`
