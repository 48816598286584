import * as React from 'react';
import './UsersDisplay.css';
import { User } from '../models/models';
import { Accordion } from '../components/accordion/Accordion';
import Button from '../components/button/Button';
import { SortUsers } from '../utils';

export interface IUsersDisplayProps {
    users: User[];
    deleteUser: (userID: string) => void;
}

export function UsersDisplay ({users, deleteUser}: IUsersDisplayProps) {

    return (
        <div className="users-display">
            <Accordion title={'Users in database'} children={
                <div className="user-list">
                    {SortUsers(users).map((u: User, index: number) => (
                        <div key={"userID" + u.userID} className='row'>
                            <p className="user-item">{index + 1}. {u.firstname} {u.lastname}</p>
                            {/* <Button onClick={() => deleteUser(u.userID)} text={'Delete user'} theme='red-white'/> */}
                        </div>
                    ))}
                </div>
            }/>
            
        </div>
    );
}
