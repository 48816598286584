import * as React from 'react';
// import {Document, Page} from 'react-pdf';
// import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
// import { useState } from 'react';
import styled from 'styled-components';
import useWindowDimensions from '../../useWindowDimensions';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export interface IPDFViewerProps {

}

export function PDFViewer (props: IPDFViewerProps) {
    
    // const [numPages, setNumPages] = useState<number>();
    // const [pageNumber, setPageNumber] = useState<number>(1);
    const { width, height } = useWindowDimensions()

    // function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    //     setNumPages(numPages);
    //   }
    return (
        <PDFContainer2>
            <PDFContainer>
                {/* <a href="https://drive.google.com/file/d/178PYA_UWI6nqAkCJuayiTxisR2X0Wm-C/preview">book</a> */}
                {width < 600 ? <iframe src="https://drive.google.com/file/d/16Egj11imB1sB-9EbOxC7eAkX32dLoePB/preview" title="pdf" width={width-50} height={height-200} allow="autoplay"></iframe> : 
                <iframe src="https://drive.google.com/file/d/16Egj11imB1sB-9EbOxC7eAkX32dLoePB/preview" width="640" height="480" allow="autoplay"></iframe>
                }
                
                {/* <Document file="https://drive.google.com/file/d/178PYA_UWI6nqAkCJuayiTxisR2X0Wm-C/view?usp=sharing" onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.apply(null, Array(numPages))
                    .map((x, i)=>i+1)
                    .map(page => width < 600 ? <Page key={"page" + page} pageNumber={page} width={width-100}/> : <Page key={"page" + page} pageNumber={page}/>)}
                </Document> */}
            </PDFContainer>
        </PDFContainer2>
    );
}

const PDFContainer2 = styled.div`
    /* border: 1px solid black; */
    padding: 1rem 0rem;
    position: relative;
    /* height: 50vh; */
    /* object-fit: contain; */
    width: 100%;
    /* aspect-ratio: 16/9; */
    /* overflow: scroll; */
    /* transform: translateX(100px);    */
    /* display: flex;
    justify-content: center; */
`

const PDFContainer = styled.div`
    /* border: 1px solid green; */
    /* height: 100vh; */
    width: 100%;
    /* aspect-ratio: 9/13; */
    /* width: 100%; */
    overflow: hidden;
    display: flex;
    justify-content: center;
    /* transform: translateX(100px); */
    /* transform: rotate(-90deg); */
`
